<template>
  <base-section id="k-d-a-story-journey" class="background-journey" space="80">
    <section :class="`px-${g_bLowestBr ? 3 : 0} pa-0 ma-0`">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" lg="11" md="11">
            <div :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-black`">{{ m_strTitle }}</div>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" :lg="`${g_bBaseBr ? 6 : 7}`" :md="`${g_bBaseBr ? 6 : 7}`" sm="12">
            <div v-for="(item, id) in m_arrContents" :key="id">
              <v-row :no-gutters="g_bLowerBr">
                <v-col :cols="g_bLowerBr ? 12 : 2">
                  <div :class="`kda-ts-${g_bLowerBr ? 16 : 24}pt nunito wt-black`">
                    {{ item.strYear }}
                  </div>
                </v-col>
                <v-col lg="1" />

                <v-col :cols="g_bLowerBr ? 12 : 9">
                  <component
                    :is="item.strComponent"
                    :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-regular py-${
                      g_bLowerBr ? 3 : 0
                    }`"
                    v-html="item.htmlText"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col v-if="g_bHighestBr" lg="1" />
          <v-col v-else-if="g_bBaseBr" lg="1" />
          <v-col v-else-if="g_bHigherBr" lg="1" />

          <v-col cols="12" :lg="`${g_bBaseBr ? 5 : 4}`" :md="`${g_bBaseBr ? 5 : 4}`" sm="12">
            <div v-if="!g_bLowerBr" class="img-container center">
              <img
                :src="require('@/assets/photos/story-04.png')"
                :class="g_bLowestBr ? 'top' : g_bBaseBr || g_bHigherBr ? 'right-base' : 'right'"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAStoryJourney',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.story.our-journey.title');
    },
    m_arrContents() {
      return [
        {
          strComponent: 'p',
          strYear: '2018',
          htmlText: this.$vuetify.lang.t('$vuetify.kda.story.our-journey.item1')
        },
        {
          strComponent: 'p',
          strYear: '2019',
          htmlText: this.$vuetify.lang.t('$vuetify.kda.story.our-journey.item2')
        },
        {
          strComponent: 'p',
          strYear: '2020',
          htmlText: this.$vuetify.lang.t('$vuetify.kda.story.our-journey.item3')
        },
        {
          strComponent: 'p',
          strYear: `2021 - ${this.$vuetify.lang.t('$vuetify.kda.story.our-journey.now')}`,
          htmlText: this.$vuetify.lang.t('$vuetify.kda.story.our-journey.item4')
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background-journey {
  background-color: #f8f8f8;
}

.img-container {
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;

  img {
    &.right {
      height: 366px;
      width: 429px;
      object-fit: cover;
    }

    &.right-base {
      height: fit-content;
      width: 100%;
      object-fit: cover;
    }

    &.top {
      opacity: 0;
    }
  }
}
</style>
